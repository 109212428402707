import React from 'react';
import Home from './Home';
import Service from './ServicesOld';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const HomeContainer = () => {
  return (
    <Container>
      <Row className="gx-0">
        {/* Left Section */}
        <Col md={6} className="mb-4 order-1 order-md-0">
          <div style={{ padding: '20px' }}>
            <Home />
          </div>
        </Col>

        {/* Right Section */}
        <Col md={6} className="mb-4 order-0 order-md-1">
          <div style={{ paddingLeft: '100px', paddingTop: '20px',  }}>
            <Service />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default HomeContainer;
