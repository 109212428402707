import React, { useEffect } from 'react';
import './Home.css'; // Import the CSS file for styling

const Home = () => {
  useEffect(() => {
    const elements = document.querySelectorAll('.animated-item');

    const handleAnimation = () => {
      elements.forEach((element, index) => {
        element.style.animationDelay = `${index * 0.5}s`;
        element.classList.add('fly-in-left');
      });
    };

    window.addEventListener('load', handleAnimation);

    return () => {
      window.removeEventListener('load', handleAnimation);
    };
  }, []);

  return (
    <div className="home-container">
      <div className={`animated-item-bottom fly-in-left`}>
        <h1>Insphile Management  Solution Pvt. Ltd.</h1>
      </div>
      <div className={`animated-item-left fly-in-bottom`}>
        <h5>Our recruitment model has been built up in consideration with all the possible needs of our clients in terms of quality and delivery. We have a large data bank of competent candidates in various fields that provide us an opportunity to evaluate from an appreciable database.
As our candidates are constantly in touch with us, we are aware of their objectives, strengths and caliber, which allows us to present the aptly suited candidate according to your specifications in terms of competency, experience, remuneration. Through a unique combination of skills, competencies, knowledge and favorable experience, INSPHILE is able to offer excellent Human Resource Solutions.
The company recognizes & caters the special personnel needs of clients in the various industries across India. Our skilled recruiters, unique database and innovative practices for varied requirements are what make us different from others. Since past 6 years at INSPHILE we are instrumental in recruitment business with a team of perfectly skilled and experienced recruiters working for us. We are dedicated towards shaping careers of active Jobseekers and through this practice we successfully manage to empower our IT and Non IT clients by providing them prominent manpower solutions in different industries /sectors at Pan India Level. With wide range of “Recruiting Tools” we believe in practicing our defined “INSPHILE - Recruitment Method & Process” with our seasoned team of recruiters who help them find prominent candidates for our clients at fast pace</h5>
      
  
      <h2>Our Mission</h2>
      <h5>To continually provide a professional, comprehensive and outstanding service to all our clients and candidates.</h5>
      <h2>Our Vision</h2>
      <h5>Through our passionate, dedicated and committed team, we aim to be the best recruitment company in INDIA, always remembering that our clients and candidates are number one.
We follow the "love what you do" approach and therefore strive to ensure that the candidate becomes an asset to a client’s business and fits the relevant culture. We will continually build, maintain and nurture solid business relationships, through regular personal contact and effective communication. We partner with our clients throughout INDIA and Middle East, to deliver customized, value-based services and solutions by understanding and listening to exactly what is needed to adequately fill a role.
Our team is committed to working hard to produce the desired results. By the same token we believe in having fun and enjoying our work. We also pride ourselves in reaching out to the community at large.</h5>
    </div>
    </div>
  );
};

export default Home;
